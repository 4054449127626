import WPAPI from "wpapi";

import { Work } from "@/types/";

const wp = new WPAPI({ endpoint: "https://w-sh.jp/wp-json/" });
wp.myCustomResourceWorks = wp.registerRoute("wp/v2", "/works/(?P<id>)");

async function getWpApiWorks(page: number): Promise<Work[]> {
  return wp
    .myCustomResourceWorks()
    .embed()
    .page(page)
    .get()
    .then(posts => {
      const works: Work[] = posts.map((work: any) => {
        const {
          acf: { company_name, staff_info, categories, video_url, created_by },
          title: { rendered }
        } = work;
        const url =
          work.thumbnail?.url ??
          (!!work._embedded &&
            work._embedded["wp:featuredmedia"][0]?.source_url) ??
          "";
        const _work = {
          rendered,
          company_name,
          categories,
          staff_info,
          url,
          video_url,
          created_by
        };
        return _work;
      });
      return works;
    })
    .catch(err => {
      if (err.data.status === 400) {
        return [];
      } else {
        console.error(err);
        throw new Error("err.api.work");
      }
    });
}

export default getWpApiWorks;
