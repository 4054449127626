
import { Vue, Options } from "vue-class-component";

import LeftSideBar from "@/components/atoms/LeftSideBar.vue";
import LeftSideInfoBar from "@/components/atoms/LeftSideInfoBar.vue";
import RightSideBarTop from "@/components/atoms/RightSideBarTop.vue";
import RightSideBarBottom from "@/components/atoms/RightSideBarBottom.vue";

import { Work } from "@/types/";

@Options<Selected>({
  components: {
    LeftSideBar,
    LeftSideInfoBar,
    RightSideBarTop,
    RightSideBarBottom
  },
  props: {
    wh: {
      type: Number,
      default: window.innerHeight
    }
  },
  watch: {
    h: [
      {
        handler: "setFillHeight",
        immediate: true,
        deep: true
      }
    ]
  }
})
export default class Selected extends Vue {
  w = window.innerWidth;

  get getSelected(): Work {
    return this.$store.state.work.work;
  }

  get getProfile(): string {
    return this.$store.state.profile;
  }

  get isPc(): boolean {
    if (this.w < 481) {
      return false;
    } else {
      return true;
    }
  }

  get playVimeoOptions(): string {
    if (this.w < 481) {
      return "?api=1&background=1&muted=1&playsinline=&autoplay=1&loop=1";
    } else {
      return "?autoplay=1&loop=1";
    }
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  mounted(): void {
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
