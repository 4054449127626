//  State
type State = {
  work: {
    work: Work | null;
    latest: Work | null;
  };
  switchSideBars: {
    isWorks: boolean;
    isAbout: boolean;
    isInfo: boolean;
  };
  members: Member[];
  works: Work[];
  categories: string[];
  filters: {
    category: string | null;
    member: string | null;
  };
};

export const state: State = {
  work: {
    work: null,
    latest: null
  },
  switchSideBars: {
    isWorks: false,
    isAbout: false,
    isInfo: false
  },
  members: [],
  works: [],
  categories: [],
  filters: {
    category: null,
    member: null
  }
};

//  Actions
import getWpApiWorks from "@/wpapi/works";
import getWpApiMembers from "@/wpapi/members";
import getWpApiCategories from "@/wpapi/categories";

import { Member, Work } from "@/types/";

type AugmentedActionContext = {
  commit<K extends keyof Mutations>(
    key: K,
    payload?: Parameters<Mutations[K]>[1]
  ): ReturnType<Mutations[K]>;
} & Omit<ActionContext<State, State>, "commit">;

interface Actions {
  GET_WORKS({ commit }: AugmentedActionContext): void;
  SELECT_WORK({ commit }: AugmentedActionContext, work: Work): void;
  CLICK_WORKS({ commit }: AugmentedActionContext): void;
  CLICK_ABOUT({ commit }: AugmentedActionContext): void;
  CLICK_INFO({ commit }: AugmentedActionContext): void;
  CLOSE_WORKS({ commit }: AugmentedActionContext): void;
  CLOSE_ABOUT({ commit }: AugmentedActionContext): void;
  CLOSE_INFO({ commit }: AugmentedActionContext): void;
  GET_MEMBERS({ commit }: AugmentedActionContext): void;
  GET_CATEGORIES({ commit }: AugmentedActionContext): void;
  SELECT_FILTERS(
    { commit }: AugmentedActionContext,
    args: {
      which: "category" | "member";
      value: string | null;
    }
  ): void;
}

const actions: ActionTree<State, State> & Actions = {
  async GET_WORKS({ commit }) {
    let page = 1;
    const works = await getWpApiWorks(page);
    while (page <= 100) {
      page++;
      const _works = await getWpApiWorks(page);
      if (_works.length === 0) {
        break;
      } else {
        works.push(..._works);
      }
    }
    commit(MutationTypes.SET_WORKS, works);
  },
  async GET_MEMBERS({ commit }) {
    let page = 1;
    const members = await getWpApiMembers(page);
    while (page <= 100) {
      page++;
      const _members = await getWpApiMembers(page);
      if (_members.length === 0) {
        break;
      } else {
        members.push(..._members);
      }
    }
    commit(MutationTypes.SET_MEMBERS, members);
  },
  async GET_CATEGORIES({ commit }) {
    const categories = await getWpApiCategories();
    commit(MutationTypes.SET_CATEGORIES, categories);
  },
  async SELECT_WORK({ commit }, work: Work) {
    commit(MutationTypes.SET_WORK, work);
  },
  async CLICK_WORKS({ commit }) {
    commit(MutationTypes.CLICK_WORKS);
  },
  async CLICK_ABOUT({ commit }) {
    commit(MutationTypes.CLICK_ABOUT);
  },
  async CLICK_INFO({ commit }) {
    commit(MutationTypes.CLICK_INFO);
  },
  async CLOSE_WORKS({ commit }) {
    commit(MutationTypes.CLOSE_WORKS);
  },
  async CLOSE_ABOUT({ commit }) {
    commit(MutationTypes.CLOSE_ABOUT);
  },
  async CLOSE_INFO({ commit }) {
    commit(MutationTypes.CLOSE_INFO);
  },
  async SELECT_FILTERS({ commit }, args) {
    const { which, value } = args;
    if (which === "category") {
      commit(MutationTypes.SET_FILTERS_CATEGORY, value);
    } else if (which === "member") {
      commit(MutationTypes.SET_FILTERS_MEMBER, value);
    }
  }
};

export enum ActionTypes {
  GET_WORKS = "GET_WORKS",
  SELECT_WORK = "SELECT_WORK",
  CLICK_WORKS = "CLICK_WORKS",
  CLICK_ABOUT = "CLICK_ABOUT",
  CLICK_INFO = "CLICK_INFO",
  CLOSE_WORKS = "CLOSE_WORKS",
  CLOSE_ABOUT = "CLOSE_ABOUT",
  CLOSE_INFO = "CLOSE_INFO",
  GET_MEMBERS = "GET_MEMBERS",
  GET_CATEGORIES = "GET_CATEGORIES",
  SELECT_FILTERS = "SELECT_FILTERS"
}

//  Mutations
import { MutationTree } from "vuex";

type Mutations<S = State> = {
  SET_WORKS(state: S, payload: Work[]): void;
  SET_WORK(state: S, payload: Work): void;
  CLICK_WORKS(state: S): void;
  CLICK_ABOUT(state: S): void;
  CLICK_INFO(state: S): void;
  CLOSE_WORKS(state: S): void;
  CLOSE_ABOUT(state: S): void;
  CLOSE_INFO(state: S): void;
  SET_MEMBERS(state: S, payload: Member[]): void;
  SET_CATEGORIES(state: S, payload: string[]): void;
  SET_FILTERS_CATEGORY(state: S, payload: string | null): void;
  SET_FILTERS_MEMBER(state: S, payload: string | null): void;
};

const mutations: MutationTree<State> & Mutations = {
  SET_WORKS(state, payload: Work[]) {
    state.works = payload;
  },
  // クリックされた作品を渡す
  SET_WORK(state, payload: Work) {
    state.work.work = payload;
  },
  // サイドバーは最新のクリックされた対象のスライドのみを表示
  CLICK_WORKS(state) {
    state.switchSideBars.isWorks = true;
    state.switchSideBars.isAbout = false;
    state.switchSideBars.isInfo = false;
  },
  CLICK_ABOUT(state) {
    state.switchSideBars.isWorks = false;
    state.switchSideBars.isAbout = true;
    state.switchSideBars.isInfo = false;
  },
  CLICK_INFO(state) {
    state.switchSideBars.isWorks = false;
    state.switchSideBars.isAbout = false;
    state.switchSideBars.isInfo = true;
  },
  CLOSE_WORKS(state) {
    state.switchSideBars.isWorks = false;
  },
  CLOSE_ABOUT(state) {
    state.switchSideBars.isAbout = false;
  },
  CLOSE_INFO(state) {
    state.switchSideBars.isInfo = false;
  },
  SET_MEMBERS(state, payload: Member[]) {
    state.members = payload;
  },
  SET_CATEGORIES(state, payload: string[]) {
    state.categories = payload;
  },
  SET_FILTERS_CATEGORY(state, payload: string) {
    const filters = state.filters;
    state.filters = {
      ...filters,
      category: payload
    };
  },
  SET_FILTERS_MEMBER(state, payload: string) {
    const filters = state.filters;
    state.filters = {
      ...filters,
      member: payload
    };
  }
};

enum MutationTypes {
  SET_WORKS = "SET_WORKS",
  SET_WORK = "SET_WORK",
  CLICK_WORKS = "CLICK_WORKS",
  CLICK_ABOUT = "CLICK_ABOUT",
  CLICK_INFO = "CLICK_INFO",
  CLOSE_WORKS = "CLOSE_WORKS",
  CLOSE_ABOUT = "CLOSE_ABOUT",
  CLOSE_INFO = "CLOSE_INFO",
  SET_MEMBERS = "SET_MEMBERS",
  SET_CATEGORIES = "SET_CATEGORIES",
  SET_FILTERS_CATEGORY = "SET_FILTERS_CATEGORY",
  SET_FILTERS_MEMBER = "SET_FILTERS_MEMBER"
}

// getters
import { GetterTree } from "vuex";

export type Getters = {
  filteredWorks(
    state: State
  ): {
    latest: Work;
    sliderWorks: Work[];
  };
};

export const getters: GetterTree<State, State> = {
  filteredWorks: state => {
    const works = JSON.parse(JSON.stringify(state.works));
    const worksFilteredByCategory =
      state.filters.category === null
        ? works
        : works.filter(work => {
            return work.categories.includes(state.filters.category as string);
          });
    const filteredAllWorks =
      state.filters.member === null
        ? worksFilteredByCategory
        : worksFilteredByCategory.filter(work => {
            return work.created_by.includes(state.filters.member as string);
          });
    return {
      latest: filteredAllWorks[0],
      sliderWorks: filteredAllWorks.slice(1, filteredAllWorks.length)
    };
  }
};

// createStore
import { Store as VuexStore, CommitOptions, DispatchOptions } from "vuex";
import { createStore, ActionTree, ActionContext } from "vuex";

// plugin for vuex to keep data after re-load
import createPersistedState from "vuex-persistedstate";

const store = createStore({
  state,
  mutations,
  actions,
  getters,
  plugins: [createPersistedState()]
});

export type Store = Omit<
  VuexStore<State>,
  "getters" | "commit" | "dispatch"
> & {
  commit<K extends keyof Mutations, P extends Parameters<Mutations[K]>[1]>(
    key: K,
    payload: P,
    options?: CommitOptions
  ): ReturnType<Mutations[K]>;
} & {
  dispatch<K extends keyof Actions>(
    key: K,
    payload: Parameters<Actions[K]>[1],
    options?: DispatchOptions
  ): ReturnType<Actions[K]>;
} & {
  getters: {
    [K in keyof Getters]: ReturnType<Getters[K]>;
  };
};

export default store;
