<template>
  <div class="slider-container w-100 h-50">
    <swiper
      v-if="works"
      class="slider-wrapper w-100 h-100"
      :loop="true"
      :slides-per-view="perView"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false
      }"
    >
      <swiper-slide
        v-for="(work, i) in works"
        :key="i"
        class="slide h-100 d-flex justify-content-center align-items-center overflow-hidden"
        v-on:mouseover="mouseOverAction(i)"
        v-on:mouseleave="mouseLeaveAction"
      >
        <img :src="work.url" class="thumbnail" alt="thumbnail-img" />
        <div
          v-if="hoverFlag === i"
          class="work-focus w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <p class="client w-50">{{ work.company_name }}</p>
          <p class="title">{{ work.rendered }}</p>
        </div>
        <router-link v-if="hoverFlag === i" class="play-btn-wrapper" to="/work">
          <button class="play-btn w-100 h-100" @click="selected(work)">
            Play
          </button>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

import SwiperCore, { Navigation, Parallax, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

import { ActionTypes } from "@/store";

SwiperCore.use([Navigation, Parallax, Autoplay]);

@Options({
  props: {
    works: Array,
    perView: Number,
    thumbnailWidth: String,
    thumbnailHeight: String
  },
  data() {
    return {};
  },
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class SliderSwiper extends Vue {
  private hoverFlag = -1;

  private mouseOverAction(index) {
    this.hoverFlag = index;
  }

  private mouseLeaveAction() {
    this.hoverFlag = -1;
  }

  private selected(selectedWork): void {
    this.$store.dispatch(ActionTypes.SELECT_WORK, selectedWork);
  }
}
</script>

<style lang="scss" scoped>
$sp: 480px;

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.slider-container {
  background-color: black;

  .slider-wrapper {
    background-color: black;

    .swiper-wrapper {
      background-color: black;
    }

    .swiper-slide {
      background-color: black;
    }
    .slide {
      position: relative;
      overflow: hidden;
      background-color: black;
      .thumbnail {
        position: absolute;
        width: 100%;
        height: auto;

        @include sp {
          width: auto;
          height: 100%;
        }
      }
      .thumbnail-video {
        position: absolute;
        width: 100%;
        height: auto;

        @include sp {
          width: auto;
          height: 100%;
        }
      }
      .work-focus {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        p {
          color: white;
        }

        .title {
          @include sp {
            width: 80%;
          }
        }
      }
      .play-btn-wrapper {
        position: absolute;
        top: 10%;
        right: 10%;
        width: 4rem;
        height: 4rem;
        font-size: 1.2rem;
        font-weight: bold;
        border: none;
        border-radius: 100%;
        background-color: white;

        @include sp {
          top: 5%;
          height: 5%;
          margin-right: -1rem;
          width: 3.5rem;
          height: 3.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .play-btn {
          border: none;
          border-radius: 100%;
          outline: none;
          text-align: center;
          transition: background-color 1s;
          display: flex;
          justify-content: center;
          align-items: center;
          &:hover {
            background-color: #c0c0c0;
          }
        }
      }
    }
  }
}
</style>
