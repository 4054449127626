<template>
  <div v-if="getSelected" class="top d-flex w-100">
    <div class="wrapper-left-bar h-100 d-flex">
      <left-side-bar />
      <left-side-info-bar
        v-if="isPc"
        :info="getSelected.staff_info"
        :title="getSelected.rendered"
      />
    </div>
    <div class="wrapper-work-and-infosp">
      <div class="wrapper-work-video d-flex flex-column align-items-center">
        <iframe
          :src="
            `https://player.vimeo.com/video/` +
              getSelected.video_url +
              playVimeoOptions
          "
          :alt="getSelected.rendered"
          class="work-video"
          autoplay
          allow="autoplay"
          frameborder="0"
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
          playsinline
        ></iframe>
        <div v-if="!isPc" class="wrapper-work-info-sp pl-4 d-flex flex-column">
          <p class="info-sp">INFO</p>
          <p class="title-sp">{{ getSelected.rendered }}</p>
          <div class="staff-info-sp" v-html="getSelected.staff_info"></div>
        </div>
      </div>
    </div>
    <div class="wrapper-right-bar h-100 d-flex flex-column">
      <right-side-bar-top :isDrawer="true" />
      <right-side-bar-bottom :isDrawer="true" :profile="getProfile" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

import LeftSideBar from "@/components/atoms/LeftSideBar.vue";
import LeftSideInfoBar from "@/components/atoms/LeftSideInfoBar.vue";
import RightSideBarTop from "@/components/atoms/RightSideBarTop.vue";
import RightSideBarBottom from "@/components/atoms/RightSideBarBottom.vue";

import { Work } from "@/types/";

@Options<Selected>({
  components: {
    LeftSideBar,
    LeftSideInfoBar,
    RightSideBarTop,
    RightSideBarBottom
  },
  props: {
    wh: {
      type: Number,
      default: window.innerHeight
    }
  },
  watch: {
    h: [
      {
        handler: "setFillHeight",
        immediate: true,
        deep: true
      }
    ]
  }
})
export default class Selected extends Vue {
  w = window.innerWidth;

  get getSelected(): Work {
    return this.$store.state.work.work;
  }

  get getProfile(): string {
    return this.$store.state.profile;
  }

  get isPc(): boolean {
    if (this.w < 481) {
      return false;
    } else {
      return true;
    }
  }

  get playVimeoOptions(): string {
    if (this.w < 481) {
      return "?api=1&background=1&muted=1&playsinline=&autoplay=1&loop=1";
    } else {
      return "?autoplay=1&loop=1";
    }
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  mounted(): void {
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
</script>

<style lang="scss" scoped>
$sp: 480px;
$wh100: calc(var(--vh, 1vh) * 100);
$wh50: calc(var(--vh, 1vh) * 50);

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

body {
  @include sp {
    height: 100vh;
    height: $wh100;
  }
}

.top {
  height: 100%;

  @include sp {
    height: $wh100;
  }
  .wrapper-left-bar {
    z-index: 10;
    width: 8%;

    @include sp {
      width: 9.7%;
    }
  }
  .wrapper-right-bar {
    width: 4%;

    @include sp {
      width: 9.7%;
    }
  }

  .wrapper-work-and-infosp {
    width: 88%;
    height: 100%;
    background-color: black;

    @include sp {
      width: 80.6%;
      background-color: white;
    }

    .wrapper-work-video {
      bottom: 0;
      left: 0;
      overflow: hidden;
      position: absolute; /* fixed の場合。absolute でも同じ */
      right: 0;
      top: 0;
      justify-content: center;

      @include sp {
        margin-left: 9.7%;
        width: 80.6%;
        height: 100%;
        overflow: hidden;
        justify-content: normal;
      }

      .work-video {
        position: absolute;
        margin-left: 4%;
        width: 177.77777778vh;
        height: 56.25vw; /* 16:9 の高さなので 56.25% (= 9 ÷ 16) */
        max-width: 87.9%;
        max-height: 100%;
        overflow: hidden;
        border: 0;

        @include sp {
          left: 50%;
          max-width: none;
          height: $wh50;
          transform: translateX(-50%);
        }
      }

      .wrapper-work-info-sp {
        position: relative;
        top: $wh50;
        width: 90%;
        height: $wh50;
        background-color: white;
        font-family: "Helvetica Neue UltraLight";
        text-align: left;
        overflow-y: auto;

        .info-sp {
          margin-top: 28.5px;
          font-size: 20px;
        }
        .title-sp {
          width: 90%;
        }
        .staff-info-sp {
          width: 90%;
          font-size: 11px;
          white-space: pre-wrap;
        }
      }
    }
  }
}
</style>
