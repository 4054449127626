<template>
  <div class="slide-bar w-100 h-50 d-flex justify-content-center" @click="open">
    <div
      class="vertical-text d-flex justify-content-center align-items-center"
      v-show="!switchAboutBar"
    >
      ABOUT
    </div>
  </div>
  <div class="outer" :v-if="isDrawer">
    <div
      class="menu-open"
      :class="{ 'open-text': switchAboutBar, 'close-text': !switchAboutBar }"
    >
      <div
        class="wrapper-close-btn h-100 d-flex justify-content-center align-items-center"
        @click="close"
        v-if="isPc"
      >
        <svg width="1rem" height="1rem" class="bi bi-chevron-right">
          <path
            fill-rule="evenodd"
            d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
          />
        </svg>
      </div>
      <div class="wrapper-contents" v-if="isPc">
        <div class="header">
          <div class="title">ABOUT</div>
          <div class="wrapper-member-options">
            <a
              v-for="member in members"
              :href="`#` + member.name_eng"
              class="member-option"
              :key="member.name"
            >
              <h4
                @click="selectFilterOption('member', member.name_eng)"
                :style="{
                  opacity: filterOptionColor('member', member.name_eng)
                }"
              >
                {{ member.name_eng }}
              </h4>
            </a>
          </div>
        </div>
        <div class="parent-wrapper-members-profile">
          <div class="wrapper-members-profile">
            <div
              class="wrapper-member-profile d-flex"
              v-for="member in members"
              :id="member.name_eng"
              :key="member.name"
            >
              <div class="about-info py-5 h-100 text-left" v-if="isPc">
                <h4 class="director mt-3 font-weight-bold">
                  WASH {{ member.position }}
                </h4>
                <h1 class="director-name font-weight-thin">
                  {{ member.name_eng }}
                </h1>
                <h3 class="director-name-jp font-weight-bold">
                  {{ member.name }}
                </h3>
                <div class="biography mt-4">
                  <h4 class="font-weight-bold">Profile</h4>
                  <div class="profile-text" v-html="member.profile" />
                </div>
              </div>
              <div
                class="wrapper-img-director d-flex justify-content-center align-items-center"
                v-if="isPc"
              >
                <img
                  :src="member.url"
                  alt="ディレクターの画像"
                  class="img-director"
                  v-if="!!member.url"
                />
                <img
                  src="https://w-sh.jp/wp-content/themes/dist/img/icon-wash-with-title.85743ca8.svg"
                  class="wash"
                  alt="wash"
                  width="135"
                  height="150"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-about-sp" v-if="!isPc">
        <img
          class="close-btn-sp"
          src="../../assets/right-chevron.png"
          @click="close"
        />
        <div class="header-sp">
          <div class="title-sp">ABOUT</div>
          <div class="wrapper-member-options-sp">
            <a
              v-for="member in members"
              :href="`#` + member.name_eng + `_sp`"
              class="member-option-sp"
              :key="member.name"
            >
              <h6
                @click="selectFilterOption('member', member.name_eng)"
                :style="{
                  opacity: filterOptionColor('member', member.name_eng)
                }"
              >
                {{ member.name_eng }}
              </h6>
            </a>
          </div>
        </div>
        <div class="wrapper-members-profile-sp">
          <div
            class="wrapper-member-profile-sp d-flex"
            v-for="member in members"
            :id="member.name_eng + `_sp`"
            :key="member.name"
          >
            <div class="wrapper-img-director-sp">
              <img
                :src="member.url"
                alt="ディレクターの画像"
                class="img-director-sp"
                v-if="!!member.url"
              />
              <img
                src="https://w-sh.jp/wp-content/themes/dist/img/icon-wash-with-title.85743ca8.svg"
                class="wash-sp"
                alt="wash"
                width="113"
                height="126"
              />
            </div>
            <div class="about-info-sp py-5 h-100 text-left">
              <h4 class="director-sp font-weight-bold">
                WASH {{ member.position }}
              </h4>
              <h1 class="director-name-sp">
                {{ member.name_eng }}
              </h1>
              <h3 class="director-name-jp-sp font-weight-bold">
                {{ member.name }}
              </h3>
              <div class="biography-sp mt-4">
                <h4 class="font-weight-bold">Profile</h4>
                <div class="profile-text" v-html="member.profile" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ActionTypes } from "@/store";
import { Member } from "@/types";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isDrawer: Boolean,
    wh: {
      type: Number,
      default: window.innerHeight
    },
    profile: String
  }
})
export default class RightSideBarTop extends Vue {
  private isPc = true;

  private w = window.innerWidth;

  get switchAboutBar(): boolean {
    return this.$store.state.switchSideBars.isAbout;
  }

  private open(): void {
    this.$store.dispatch(ActionTypes.CLICK_ABOUT);
  }
  private close(): void {
    this.$store.dispatch(ActionTypes.CLOSE_ABOUT);
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  get getIcon(): string {
    return this.$store.state.assets.icon ?? "";
  }

  get members(): Member[] {
    return this.$store.state.members;
  }

  private selectFilterOption(
    which: "category" | "member",
    value: string | null
  ): void {
    this.$store.dispatch(ActionTypes.SELECT_FILTERS, { which, value });
  }

  private filterOptionColor(
    which: "category" | "member",
    value: string
  ): string {
    return this.$store.state.filters[which] === value ? "50%" : "100%";
  }

  created(): void {
    this.$store.dispatch(ActionTypes.CLOSE_ABOUT);
    this.$store.dispatch(ActionTypes.GET_MEMBERS);
  }

  mounted(): void {
    if (this.w < 481) {
      this.isPc = false;
    }
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
</script>

<style lang="scss" scoped>
$sp: 480px;
$wh100: calc(var(--vh, 1vh) * 100);
$wh-100: calc(var(--vh, 1vh) * -100);
$wh50: calc(var(--vh, 1vh) * 50);

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

* {
  scroll-behavior: smooth;
  color: black;
}

.slide-bar {
  background-color: #f8ff40;
  z-index: 0;
  &:hover {
    cursor: pointer;
  }
  .vertical-text {
    font-size: 23px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    @include sp {
      font-size: 20px;
    }
  }
}

.outer {
  position: fixed;
  z-index: 100;

  .menu-open {
    position: absolute;
    z-index: 10;
    top: 0;
    right: -100vw;
    width: 96vw;
    height: $wh100;
    display: flex;
    background-color: #f8ff40;

    @include sp {
      right: -100vw;
      width: 91.5vw;
      height: $wh100;
    }

    .wrapper-contents {
      flex-grow: 1;
      display: flex;
      flex-direction: column;

      .header {
        padding-left: 5.5%;
        display: flex;
        width: 100%;
        height: 80px;
        padding-bottom: 43px;
        font-size: 20px;
        border-bottom: 1px solid black;

        .title {
          padding-top: 15px;
        }

        .wrapper-member-options {
          flex-grow: 1;
          display: flex;
          flex-wrap: wrap;
          .member-option {
            padding-top: 15px;
            padding-left: 44px;
            color: black;
            &:hover {
              cursor: pointer;
              text-decoration: underline solid black;
            }
          }
        }
      }

      .parent-wrapper-members-profile {
        padding-left: 5.5%;
        flex-grow: 1;
        overflow: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }
      }
      .wrapper-members-profile {
        display: flex;
        flex-direction: column;

        .wrapper-member-profile {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    .wrapper-close-btn {
      position: absolute;
      top: 0;
      left: 0;
      width: 5.5%;
      &:hover {
        cursor: pointer;
      }
    }

    .about-info {
      width: 60%;
      overflow-y: scroll;
      /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
      -ms-overflow-style: none;
      /*Firefoxへの対応*/
      scrollbar-width: none;
      // (chrome, safari) hide scroll bar
      &::-webkit-scrollbar {
        display: none;
      }
      .director,
      .biography {
        font-size: 20px;
        .profile-text {
          font-size: 18px;
          white-space: pre-line;
        }
      }
      .director-name {
        font-size: 65px;
        font-weight: 100;
        font-family: "Helvetica Neue UltraLight", Helvetica, Arial, sans-serif;
      }
      .biography {
        font-size: 20px;
        li {
          list-style: none;
        }
      }
    }

    .wrapper-img-director {
      position: relative;
      width: 30%;
      max-width: 400px;

      .img-director {
        width: 100%;
        height: auto;
      }
    }

    .wash {
      position: absolute;
      left: -110px;
    }
  }
}
.open-text {
  transform: translateX(-96vw);
  transition: 1s;
  @include sp {
    transform: translateX(-91.5vw);
    transition: 0.6s;
  }
}

.close-text {
  transform: translateX(0);
  transition: 0.8s;
}

// sp

.wrapper-about-sp {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header-sp {
    padding: 13px 0 16px 23px;
    border-bottom: 1px solid black;

    .title-sp {
      font-size: 26px;
      text-align: left;
    }

    .wrapper-member-options-sp {
      display: flex;
      flex-wrap: wrap;
      .member-option-sp {
        padding-top: 10px;
        padding-right: 44px;
        color: black;
        &:hover {
          cursor: pointer;
          text-decoration: underline solid black;
        }
      }
    }
  }

  .close-btn-sp {
    z-index: 1;
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 28px;
  }

  .wrapper-members-profile-sp {
    padding-top: 30px;
    flex-grow: 1;
    overflow: hidden;
    overflow-y: scroll;
    -ms-overflow-style: none; /* Internet Explorer 10+ */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera*/
    }

    .wrapper-member-profile-sp {
      margin-left: 54px;
      display: flex;
      flex-direction: column;

      .wrapper-img-director-sp {
        position: relative;
        min-height: 150px;
        .img-director-sp {
          max-width: 100%;
          height: auto;
          margin-left: 4%;
        }

        .wash-sp {
          position: absolute;
          width: 85px;
          height: 85px;
          left: 0;
          bottom: 0;
        }
      }

      .about-info-sp {
        .director-sp {
          font-size: 14px;
        }
        .director-name-sp {
          font-size: 35px;
          font-weight: 100;
          font-family: "Helvetica Neue UltraLight", Helvetica, Arial, sans-serif;
        }
        .director-name-jp-sp {
          font-size: 20px;
        }
        .biography-sp {
          font-size: 18px;
          .profile-text {
            padding-right: 20px;
            font-size: 14px;
            white-space: pre-line;
          }
        }
      }
    }
  }

  .wrapper-about-info-sp {
    position: relative;
    bottom: 0;
    width: 100%;
    margin-top: 40px;
    padding-left: 33.5px;
    padding-bottom: 50px;
    text-align: left;
    /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
    -ms-overflow-style: none;
    /*Firefoxへの対応*/
    scrollbar-width: none;
    // (chrome, safari) hide scroll bar
    &::-webkit-scrollbar {
      display: none;
    }

    .director-sp {
      font-size: 14px;
    }
    .director-name-sp {
      font-size: 50px;
      font-weight: 500;
    }
    .director-name-jp-sp {
      font-size: 25px;
    }

    .wrapper-biography-sp {
      margin-top: 30px;
      list-style-type: none;

      .biography-sp {
        font-size: 22px;
        font-weight: bold;
      }
      .profile-text {
        margin-right: 30px;
        white-space: pre-line;
        font-size: 14px;
      }
      li {
        font-size: 11px;
      }
    }
  }
}
</style>
