import WPAPI from "wpapi";

import { Member } from "@/types/";

const wp = new WPAPI({ endpoint: "https://w-sh.jp/wp-json/" });
wp.myCustomResourceWorks = wp.registerRoute("wp/v2", "/members/(?P<id>)");

async function getWpApiMembers(page: number): Promise<Member[]> {
  return wp
    .myCustomResourceWorks()
    .embed()
    .page(page)
    .get()
    .then(posts => {
      const members: Member[] = posts.map((member: any) => {
        const {
          acf: { name, name_eng, position, profile },
          title: { rendered }
        } = member;
        const url =
          member.thumbnail?.url ??
          (!!member._embedded &&
            member._embedded["wp:featuredmedia"][0]?.source_url) ??
          "";
        const _member = {
          rendered,
          name,
          name_eng,
          position,
          profile,
          url
        };
        return _member;
      });
      return members.reverse();
    })
    .catch(err => {
      if (err.data.status === 400) {
        return [];
      } else {
        console.error(err);
        throw new Error("err.api.work");
      }
    });
}

export default getWpApiMembers;
