
import { Vue, Options } from "vue-class-component";

import LeftSideBar from "@/components/atoms/LeftSideBar.vue";
import RightSideBarTop from "@/components/atoms/RightSideBarTop.vue";
import RightSideBarBottom from "@/components/atoms/RightSideBarBottom.vue";
import SliderSwiper from "@/components/molecules/SliderSwiper.vue";

import { Work } from "@/types";
import { ActionTypes } from "@/store";

@Options({
  components: {
    LeftSideBar,
    RightSideBarTop,
    RightSideBarBottom,
    SliderSwiper
  },
  props: {
    wh: {
      type: Number,
      defalut: window.innerHeight
    }
  },
  watch: {
    h: [
      {
        handler: "setFillHeight",
        immediate: true,
        deep: true
      }
    ]
  }
})
export default class Top extends Vue {
  protected hoverFlag = false;
  private upperRow = 2;
  private lowerRow = 3;

  private w = window.innerWidth;

  private mouseOverAction() {
    this.hoverFlag = true;
  }

  get judgeUpperRow(): number {
    if (this.w < 481) {
      this.upperRow = 1;
    } else {
      this.upperRow = 2;
    }
    return this.upperRow;
  }

  get judgeLowerRow(): number {
    if (this.w < 481) {
      this.lowerRow = 1;
    } else {
      this.lowerRow = 3;
    }
    return this.lowerRow;
  }

  mouseLeaveAction(): void {
    this.hoverFlag = false;
  }

  get sliderWorksUpper(): Work[] {
    return this.$store.getters.filteredWorks.sliderWorks;
  }

  get sliderWorksBottom(): Work[] {
    const works = [...this.$store.getters.filteredWorks.sliderWorks];
    return works.reverse();
  }

  get latestWork(): Work {
    return this.$store.getters.filteredWorks.latest;
  }

  get getProfile(): string {
    return this.$store.state.profile;
  }

  private selected(): void {
    this.$store.dispatch(
      ActionTypes.SELECT_WORK,
      this.$store.getters.filteredWorks.latest
    );
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  mounted(): void {
    this.$store.dispatch(ActionTypes.GET_WORKS);
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
