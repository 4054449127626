<template>
  <div class="slide-bar w-100 h-50 d-flex justify-content-center" @click="open">
    <div
      class="vertical-text d-flex justify-content-center align-items-center"
      v-if="!switchWorksBar"
    >
      WORKS
    </div>
  </div>
  <div class="outer" :v-if="isDrawer">
    <div
      class="wrapper-menu-open d-flex"
      :class="{ 'open-text': switchWorksBar, 'close-text': !switchWorksBar }"
    >
      <div class="wrapper-close-btn" @click="close">
        <div class="close-chevron">
          <img
            src="../../assets/right-chevron.png"
            class="close-chevron-path"
          />
        </div>
      </div>
      <div class="wrapper-menus">
        <div class="wrapper-menu wrapper-menu-top d-flex flex-column">
          <div class="menu-open d-flex flex-column w-100">
            <router-link to="/" class="menu-item">
              <h3
                class="parent-group"
                @click="selectFilterOption('member', null)"
              >
                MEMBERS
              </h3>
            </router-link>
            <div class="menu">
              <router-link
                to="/"
                class="menu-item"
                v-for="member in members"
                :key="member.name"
              >
                <h4
                  @click="selectFilterOption('member', member.name_eng)"
                  :style="{
                    opacity: filterOptionColor('member', member.name_eng)
                  }"
                >
                  {{ member.name_eng }}
                </h4>
              </router-link>
            </div>
          </div>
        </div>
        <div class="wrapper-menu d-flex flex-column">
          <div class="menu-open d-flex flex-column w-100">
            <router-link to="/" class="menu-item">
              <h3
                class="parent-group"
                @click="selectFilterOption('category', null)"
              >
                WORKS
              </h3>
            </router-link>
            <div class="menu">
              <router-link
                to="/"
                v-for="category in categories"
                :key="category"
                class="menu-item"
              >
                <h4
                  @click="selectFilterOption('category', category)"
                  :style="{ opacity: filterOptionColor('category', category) }"
                >
                  {{ category }}
                </h4>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

import { ActionTypes } from "@/store";
import { Member } from "@/types";

@Options({
  props: {
    isDrawer: Boolean,
    wh: {
      type: Number,
      defalut: window.innerHeight
    }
  }
})
export default class RightSideBarTop extends Vue {
  get switchWorksBar(): boolean {
    return this.$store.state.switchSideBars.isWorks;
  }

  get members(): Member[] {
    return this.$store.state.members;
  }

  get categories(): string[] {
    return this.$store.state.categories.reverse();
  }

  private open(): void {
    this.$store.dispatch(ActionTypes.CLICK_WORKS);
  }
  private close(): void {
    this.$store.dispatch(ActionTypes.CLOSE_WORKS);
  }

  private selectFilterOption(
    which: "category" | "member",
    value: string | null
  ): void {
    this.$store.dispatch(ActionTypes.SELECT_FILTERS, { which, value });
  }

  private filterOptionColor(
    which: "category" | "member",
    value: string
  ): string {
    return this.$store.state.filters[which] === value ? "50%" : "100%";
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  created(): void {
    this.$store.dispatch(ActionTypes.CLOSE_WORKS);
  }

  mounted(): void {
    this.$store.dispatch(ActionTypes.GET_CATEGORIES);
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
</script>

<style lang="scss" scoped>
$sp: 480px;
$wh100: calc(var(--vh, 1vh) * 100);
$wh50: calc(var(--vh, 1vh) * 50);
$wh-50: calc(var(--vh, 1vh) * -50);

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.slide-bar {
  position: relative;
  z-index: 0;
  background-color: #bfed56;

  &:hover {
    cursor: pointer;
  }
  .vertical-text {
    font-size: 23px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    @include sp {
      font-size: 20px;
    }
  }
}

.outer {
  position: relative;
  z-index: 1;
  .wrapper-menu-open {
    position: absolute;
    z-index: 1;
    top: -50vh;
    right: -50vw;
    width: 50vw;
    height: 50vh;
    display: flex;
    background-color: #bfed56;

    @include sp {
      top: $wh-50;
      right: -91.5vw;
      width: 91.5vw;
      height: $wh100;
    }
    .wrapper-close-btn {
      width: 15%;
      padding-left: 24px;
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
      }
      .close-chevron,
      .close-chevron-path {
        width: 16px;
        height: 16px;
        @include sp {
          width: 24px;
          height: 28px;
        }
      }
    }

    .wrapper-menus {
      width: 85%;
      height: 100%;
      display: flex;

      @include sp {
        margin-top: 30px;
        margin-right: 10px;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }
      }
    }

    .wrapper-menu-top {
      @include sp {
        margin-bottom: 100px;
      }
    }
    .wrapper-menu {
      width: 50%;
      height: 100%;

      @include sp {
        width: 100%;
        height: auto;
      }

      .menu-open {
        width: 100%;
        height: 100%;
        margin-top: 1.2rem;
        padding-right: 0.5rem;
        text-align: right;
        overflow: hidden;
        overflow-y: scroll;
        -ms-overflow-style: none; /* Internet Explorer 10+ */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          display: none; /* Chrome, Safari, Opera*/
        }

        .parent-group {
          font-size: 20px;
          color: black !important;
          font-family: "Helvetica Neue UltraLight", Helvetica, Arial, sans-serif;
          @include sp {
            font-size: 26px;
          }
        }
        .menu {
          margin-top: 1.5rem;
          @include sp {
            margin-top: 22px;
            margin-right: 14px;
          }
          h4 {
            font-size: 18px;
            color: black;
            @include sp {
              font-size: 25px;
            }
            &:hover {
              cursor: pointer;
              border-bottom: black;
            }
          }
        }
        .menu-item {
          color: black;
          :hover {
            cursor: pointer;
            text-decoration: underline solid black;
          }
        }
      }
    }
  }
}

.open-text {
  transform: translateX(-50vw);
  transition: 1.5s;
  @include sp {
    transform: translateX(-91.5vw);
    transition: 0.6s;
  }
}

.close-text {
  transform: translateX(0);
  transition: 0.8s;
}
</style>
