<template>
  <div class="top d-flex w-100 h-100">
    <div class="wrapper-left-bar h-100">
      <left-side-bar />
    </div>
    <div class="works-content h-100 d-flex flex-column">
      <div
        v-if="latestWork"
        class="wrapper-work-fixed w-100 h-50 d-flex justify-content-center align-items-center"
        v-on:mouseover="mouseOverAction"
        v-on:mouseleave="mouseLeaveAction"
      >
        <img
          :src="latestWork.url"
          class="work-fixed"
          alt="最新の作品のサムネイル画像"
        />
        <div
          v-if="hoverFlag"
          class="work-focus w-100 h-100 d-flex flex-column justify-content-center align-items-center"
        >
          <div
            class="work-overview h-100 d-flex flex-column justify-content-center align-items-center"
          >
            <p class="client">{{ latestWork.company_name }}</p>
            <p class="title">{{ latestWork.rendered }}</p>
          </div>
        </div>
        <router-link class="play-btn-wrapper" to="/work" v-if="hoverFlag">
          <button class="play-btn w-100 h-100" @click="selected">Play</button>
        </router-link>
      </div>
      <div
        class="wrapper-slider w-100 h-50 d-flex flex-column"
        v-if="sliderWorksUpper"
      >
        <slider-swiper
          class="slider"
          :works="sliderWorksUpper"
          :perView="judgeUpperRow"
        />
        <slider-swiper
          class="slider"
          :works="sliderWorksBottom"
          :perView="judgeLowerRow"
        />
      </div>
    </div>
    <div class="wrapper-right-bar h-100 d-flex flex-column">
      <right-side-bar-top :isDrawer="true" />
      <right-side-bar-bottom :isDrawer="true" :profile="getProfile" />
    </div>
  </div>
</template>

<script lang="ts">
import { Vue, Options } from "vue-class-component";

import LeftSideBar from "@/components/atoms/LeftSideBar.vue";
import RightSideBarTop from "@/components/atoms/RightSideBarTop.vue";
import RightSideBarBottom from "@/components/atoms/RightSideBarBottom.vue";
import SliderSwiper from "@/components/molecules/SliderSwiper.vue";

import { Work } from "@/types";
import { ActionTypes } from "@/store";

@Options({
  components: {
    LeftSideBar,
    RightSideBarTop,
    RightSideBarBottom,
    SliderSwiper
  },
  props: {
    wh: {
      type: Number,
      defalut: window.innerHeight
    }
  },
  watch: {
    h: [
      {
        handler: "setFillHeight",
        immediate: true,
        deep: true
      }
    ]
  }
})
export default class Top extends Vue {
  protected hoverFlag = false;
  private upperRow = 2;
  private lowerRow = 3;

  private w = window.innerWidth;

  private mouseOverAction() {
    this.hoverFlag = true;
  }

  get judgeUpperRow(): number {
    if (this.w < 481) {
      this.upperRow = 1;
    } else {
      this.upperRow = 2;
    }
    return this.upperRow;
  }

  get judgeLowerRow(): number {
    if (this.w < 481) {
      this.lowerRow = 1;
    } else {
      this.lowerRow = 3;
    }
    return this.lowerRow;
  }

  mouseLeaveAction(): void {
    this.hoverFlag = false;
  }

  get sliderWorksUpper(): Work[] {
    return this.$store.getters.filteredWorks.sliderWorks;
  }

  get sliderWorksBottom(): Work[] {
    const works = [...this.$store.getters.filteredWorks.sliderWorks];
    return works.reverse();
  }

  get latestWork(): Work {
    return this.$store.getters.filteredWorks.latest;
  }

  get getProfile(): string {
    return this.$store.state.profile;
  }

  private selected(): void {
    this.$store.dispatch(
      ActionTypes.SELECT_WORK,
      this.$store.getters.filteredWorks.latest
    );
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  mounted(): void {
    this.$store.dispatch(ActionTypes.GET_WORKS);
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
</script>

<style lang="scss">
$sp: 480px;
$wh100: calc(var(--vh, 1vh) * 100);

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

#app {
  width: 100vw;
  height: $wh100;
  overflow: hidden;
}

.top {
  .wrapper-left-bar,
  .wrapper-right-bar {
    width: 4vw;
    @include sp {
      width: 9.7%;
    }
  }

  .works-content {
    width: 92vw;
    overflow: hidden;

    .wrapper-work-fixed {
      position: relative;
      overflow: hidden;

      .work-fixed,
      .work-video {
        position: absolute;
        width: 100%;
        height: auto;

        @include sp {
          width: auto;
          height: 100%;
        }
      }
      .work-focus {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        p {
          color: white;
        }
        .client {
          font-size: 1.5rem;
        }
        .title {
          font-size: 1.2rem;
        }

        .work-overview {
          width: 50%;

          @include sp {
            width: 80%;
          }
        }
      }
      .play-btn-wrapper {
        position: absolute;
        right: 15%;
        width: 5rem;
        height: 5rem;
        font-size: 1.2rem;
        border: none;
        border-radius: 100%;
        background-color: white;

        @include sp {
          top: 1rem;
          right: 1rem;
        }

        .play-btn {
          border: none;
          border-radius: 100%;
          outline: none;
          transition: background-color 1s;
          &:hover {
            background-color: #c0c0c0;
          }
        }
      }
    }
  }
}
</style>
