
import { ActionTypes } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isDrawer: Boolean,
    info: String,
    title: String
  }
})
export default class RightSideBarTop extends Vue {
  private info!: string;
  private isVertical = true;
  private isOpen = false;
  private isClose = false;

  get switchInfoBar(): boolean {
    return this.$store.state.switchSideBars.isInfo;
  }

  private open(): void {
    this.isVertical = false;
    this.isOpen = true;
    this.isClose = false;
    this.$store.dispatch(ActionTypes.CLICK_INFO);
  }
  private close(): void {
    this.isVertical = true;
    this.isOpen = false;
    this.isClose = true;
    this.$store.dispatch(ActionTypes.CLOSE_INFO);
  }

  created(): void {
    this.$store.dispatch(ActionTypes.CLOSE_INFO);
  }
}
