
import { Options, Vue } from "vue-class-component";

import { ActionTypes } from "@/store";
import { Member } from "@/types";

@Options({
  props: {
    isDrawer: Boolean,
    wh: {
      type: Number,
      defalut: window.innerHeight
    }
  }
})
export default class RightSideBarTop extends Vue {
  get switchWorksBar(): boolean {
    return this.$store.state.switchSideBars.isWorks;
  }

  get members(): Member[] {
    return this.$store.state.members;
  }

  get categories(): string[] {
    return this.$store.state.categories.reverse();
  }

  private open(): void {
    this.$store.dispatch(ActionTypes.CLICK_WORKS);
  }
  private close(): void {
    this.$store.dispatch(ActionTypes.CLOSE_WORKS);
  }

  private selectFilterOption(
    which: "category" | "member",
    value: string | null
  ): void {
    this.$store.dispatch(ActionTypes.SELECT_FILTERS, { which, value });
  }

  private filterOptionColor(
    which: "category" | "member",
    value: string
  ): string {
    return this.$store.state.filters[which] === value ? "50%" : "100%";
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  created(): void {
    this.$store.dispatch(ActionTypes.CLOSE_WORKS);
  }

  mounted(): void {
    this.$store.dispatch(ActionTypes.GET_CATEGORIES);
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
