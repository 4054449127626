<template>
  <div class="slide-bar w-100 h-100 d-flex justify-content-center">
    <div
      class="vertical-text d-flex justify-content-center align-items-center"
      v-if="!switchInfoBar"
      @click="open"
    >
      INFO
    </div>
  </div>
  <div class="outer" :v-if="isDrawer">
    <div
      class="wrapper-menu-open d-flex"
      :class="{ 'open-text': switchInfoBar, 'close-text': !switchInfoBar }"
    >
      <div class="wrapper-staff-info d-flex flex-column">
        <p class="info">INFO</p>
        <p class="title">{{ title }}</p>
        <div v-html="info" class="staff-info"></div>
      </div>
      <div
        class="wrapper-close-btn d-flex justify-content-center align-items-center"
        @click="close"
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16.2426 6.34317L14.8284 4.92896L7.75739 12L14.8285 19.0711L16.2427 17.6569L10.5858 12L16.2426 6.34317Z"
            fill="currentColor"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { ActionTypes } from "@/store";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isDrawer: Boolean,
    info: String,
    title: String
  }
})
export default class RightSideBarTop extends Vue {
  private info!: string;
  private isVertical = true;
  private isOpen = false;
  private isClose = false;

  get switchInfoBar(): boolean {
    return this.$store.state.switchSideBars.isInfo;
  }

  private open(): void {
    this.isVertical = false;
    this.isOpen = true;
    this.isClose = false;
    this.$store.dispatch(ActionTypes.CLICK_INFO);
  }
  private close(): void {
    this.isVertical = true;
    this.isOpen = false;
    this.isClose = true;
    this.$store.dispatch(ActionTypes.CLOSE_INFO);
  }

  created(): void {
    this.$store.dispatch(ActionTypes.CLOSE_INFO);
  }
}
</script>

<style lang="scss" scoped>
.slide-bar {
  background-color: white;
  &:hover {
    cursor: pointer;
  }
  .vertical-text {
    font-size: 23px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
  }
}

.outer {
  position: relative;
  // overflow: hidden;
  .wrapper-menu-open {
    position: absolute;
    z-index: 10;
    top: 0;
    left: -35vw;
    width: 25vw;
    height: 100vh;
    background-color: white;
    .wrapper-staff-info {
      margin-top: 2.5rem;
      width: 80%;
      .info,
      .title {
        margin-left: 1rem;
        text-align: left;
        font-size: 20px;
      }
      .staff-info {
        margin-left: 0.8rem;
        text-align: left;
        font-size: 12px;
        white-space: pre-wrap;
      }
    }
    .wrapper-close-btn {
      width: 20%;
      &:hover {
        cursor: pointer;
      }
    }
  }
}
.open-text {
  transform: translateX(31vw);
  transition: 1s;
}

.close-text {
  transform: translateX(0);
  transition: 1s;
}
</style>
