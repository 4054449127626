import WPAPI from "wpapi";

const wp = new WPAPI({ endpoint: "https://w-sh.jp/wp-json/" });
wp.myCustomResourceWorks = wp.registerRoute("wp/v2", "/categories/");

async function getWpApiCategories(): Promise<string[]> {
  return wp
    .myCustomResourceWorks()
    .get()
    .then(posts => {
      const categories: string[] = posts.map((category: any) => {
        const {
          title: { rendered }
        } = category;
        return rendered;
      });
      return categories;
    })
    .catch(err => {
      console.error(err);
      throw new Error("err.api.work");
    });
}

export default getWpApiCategories;
