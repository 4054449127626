<template>
  <div
    class="slide-bar w-100 h-100 d-flex justify-content-center align-items-center"
    @click="onClick"
  >
    <div class="icon-wash">
      <img
        class="w-100"
        src="https://w-sh.jp/wp-content/themes/dist/img/icon-wash.828b6702.svg"
      />
    </div>
    <div class="vertical-text">
      WASH
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from "vue-class-component";

export default class LeftSideBar extends Vue {
  private onClick(): void {
    this.$router.push("/");
  }
}
</script>

<style lang="scss" scoped>
$sp: 480px;

@mixin sp {
  @media (max-width: ($sp)) {
    @content;
  }
}

.slide-bar {
  position: relative;
  z-index: 100;
  background-color: #56e9ed;
  &:hover {
    cursor: pointer;
  }

  .icon-wash {
    position: absolute;
    top: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 90%;
  }
  .slide-router {
    width: 100%;
    height: 100%;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .vertical-text {
    color: black;
    font-size: 23px;
    writing-mode: vertical-lr;
    transform: rotate(180deg);
    :hover {
      color: black;
    }
    @include sp {
      font-size: 20px;
    }
  }
}
</style>
