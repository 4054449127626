
import { ActionTypes } from "@/store";
import { Member } from "@/types";
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    isDrawer: Boolean,
    wh: {
      type: Number,
      default: window.innerHeight
    },
    profile: String
  }
})
export default class RightSideBarTop extends Vue {
  private isPc = true;

  private w = window.innerWidth;

  get switchAboutBar(): boolean {
    return this.$store.state.switchSideBars.isAbout;
  }

  private open(): void {
    this.$store.dispatch(ActionTypes.CLICK_ABOUT);
  }
  private close(): void {
    this.$store.dispatch(ActionTypes.CLOSE_ABOUT);
  }

  private setFillHeight(): void {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  }

  get getIcon(): string {
    return this.$store.state.assets.icon ?? "";
  }

  get members(): Member[] {
    return this.$store.state.members;
  }

  private selectFilterOption(
    which: "category" | "member",
    value: string | null
  ): void {
    this.$store.dispatch(ActionTypes.SELECT_FILTERS, { which, value });
  }

  private filterOptionColor(
    which: "category" | "member",
    value: string
  ): string {
    return this.$store.state.filters[which] === value ? "50%" : "100%";
  }

  created(): void {
    this.$store.dispatch(ActionTypes.CLOSE_ABOUT);
    this.$store.dispatch(ActionTypes.GET_MEMBERS);
  }

  mounted(): void {
    if (this.w < 481) {
      this.isPc = false;
    }
    window.addEventListener("resize", this.setFillHeight);
  }

  beforeUnmount(): void {
    window.removeEventListener("resize", this.setFillHeight);
  }
}
