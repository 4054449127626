
import { Vue, Options } from "vue-class-component";

import SwiperCore, { Navigation, Parallax, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper.scss";

import { ActionTypes } from "@/store";

SwiperCore.use([Navigation, Parallax, Autoplay]);

@Options({
  props: {
    works: Array,
    perView: Number,
    thumbnailWidth: String,
    thumbnailHeight: String
  },
  data() {
    return {};
  },
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class SliderSwiper extends Vue {
  private hoverFlag = -1;

  private mouseOverAction(index) {
    this.hoverFlag = index;
  }

  private mouseLeaveAction() {
    this.hoverFlag = -1;
  }

  private selected(selectedWork): void {
    this.$store.dispatch(ActionTypes.SELECT_WORK, selectedWork);
  }
}
